var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-set-room-fee",attrs:{"title":(_vm.preTitle + "宿舍费用设置"),"visible":_vm.show,"before-close":_vm.close,"width":"520px"},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{on:{"click":_vm.close}},[_vm._v("关 闭")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"0"}},[_c('el-table',{attrs:{"data":_vm.formData.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"label":"学年","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" 第"+_vm._s(row.year)+"学年 ")]}}])}),_c('el-table-column',{attrs:{"label":"宿舍费用"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEditing)?_c('el-form-item',{attrs:{"prop":("tableData." + $index + ".roomPrice"),"rules":_vm.rules.roomPrice}},[_c('el-input',{attrs:{"placeholder":"请输入宿舍费用"},model:{value:(row.roomPrice),callback:function ($$v) {_vm.$set(row, "roomPrice", $$v)},expression:"row.roomPrice"}})],1):_c('div',{class:[{'font-grey':row.disabled}]},[_vm._v(_vm._s(row.roomPrice))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isEditing)?[_c('el-form-item',[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.cancel(row)}}},[_vm._v("取消")]),(row.isEditing)?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.handleSave(row)}}},[_vm._v("保存 ")]):_vm._e()],1)]:[(!_vm.isEditing&&!row.disabled)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['searchOrder:payType:edit']),expression:"['searchOrder:payType:edit']"}],attrs:{"size":"small","type":"primary","plain":""},on:{"click":function($event){return _vm.editRow(row)}}},[_vm._v("编辑 ")])]:_vm._e()]]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }