<template>
  <div>
    <el-dialog class="stu-order-detail-dialog" :title="`订单详情${title}`" :visible.sync="show"
               v-loading="loadData.loading" width="1300px" :before-close="handleClose">
      <el-descriptions direction="vertical" :column="7" border>
        <el-descriptions-item label="录取编号">
          {{ userInfo.examineNo }}
        </el-descriptions-item>
        <el-descriptions-item label="姓名">
          {{ userInfo.stuName }}
        </el-descriptions-item>
        <el-descriptions-item label="性别">
          {{ Number(userInfo.sexType) === 1 ? '男' : (Number(userInfo.sexType) === 2 ? '女' : '') }}
        </el-descriptions-item>
        <el-descriptions-item label="院系">
          {{ userInfo.collegeName }}
        </el-descriptions-item>
        <el-descriptions-item label="专业">
          {{ userInfo.majorName }}
        </el-descriptions-item>
        <el-descriptions-item label="班级">
          {{ userInfo.className ? userInfo.className : '未绑定' }}
        </el-descriptions-item>
        <el-descriptions-item label="宿舍">
          <span v-if="userInfo.dormitoryName && userInfo.floorNum">{{ userInfo.dormitoryName }}{{ userInfo.roomName
            }}</span>
          <span v-else>未分配</span>
        </el-descriptions-item>
      </el-descriptions>
      <!-- 学年订单 -->
      <div class="order-table" v-for="(tableData, index) in orderList" :key="index">
        <div class="order-info">
          <span>{{ userInfo.grade }}级收费标准</span>
          <template v-if="majorPriceList.length">【第{{
              index + 1 === 1 ? '一' : index + 1 === 2 ? '二' : '三'
            }}学年】：学费：<b style="color: var(--color-primary);">{{
                majorPriceList[index].allFee
              }}</b>，
            <span v-if="roomPrices[index]">宿舍费：<b style="color: var(--color-primary);">{{
                roomPrices[index].roomPrice
              }}</b>，</span>
            <span v-if="roomPrices[index]">合计：<b style="color: var(--color-primary);">{{
                handlePlus(majorPriceList[index].allFee,
                  roomPrices[index].roomPrice)
              }}</b>，</span>
            <span>总缴费：<b class="price-style">{{ allPaidAmount[index] }}</b>，</span>
            <span>总减免：<b class="price-style">{{ allDeductAmount[index]
              }}</b>，</span>
            <span v-if="getOwnAmount(index)<0">待退还：<b class="price-style"
                                                         style="color:var(--color-warning)">{{ Math.abs(getOwnAmount(index))
              }}</b></span>
            <span v-if="roomPrices[index]&&getOwnAmount(index)>=0">欠费：<b class="price-style">
              {{ getOwnAmount(index) }}
              </b></span>
          </template>
        </div>
        <template v-if="tableData.length">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column type="index" label="次数" width="50" />
            <el-table-column prop="payDate" label="日期" width="100" />
            <el-table-column prop="payType" label="缴费方式" width="180" />
            <el-table-column prop="payAmount" label="缴费金额" width="100">
              <template v-slot="{ row }">
                {{ row.payAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="deductAmount" label="减免金额" width="100" />
            <el-table-column prop="payOrderNo" label="缴费单号" width="180" />
            <el-table-column prop="cashierUserRealName" label="收费人" width="110" />
            <el-table-column prop="remarks" class-name="remarks" label="备注" min-width="180">
              <template v-slot="{ row }">
                <template v-if="row.isEditing">
                  <el-form ref="form" :model="formData" size="small" :rules="rules">
                    <el-form-item prop="remarks" style="margin-bottom: 16px;">
                      <el-input v-model.trim="formData.remarks" />
                    </el-form-item>
                  </el-form>
                </template>
                <span style="vertical-align: 12px;" v-else>{{ row.remarks }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="240" fixed="right">
              <template v-slot="{ row }">
                <template v-if="row.isEditing">
                  <el-button @click="cancel" type="text">
                    取消
                  </el-button>
                  <el-button v-permission="['stu:order:edit']" type="primary" size="small" @click="saveRemarks(row)">保存
                  </el-button>
                </template>
                <template v-if="!row.isEditing && !isEditing">
                  <el-button size="mini" type="primary" @click="previewBill(row)">打印预览</el-button>
                  <el-button v-permission="['stu:order:cancel']" @click="undo(row)" type="text">
                    撤销
                  </el-button>
                  <el-button v-permission="['stu:order:edit']" type="text" @click="showEditRemarks(row)">修改备注
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <p v-else class="empty-area">--暂无缴费记录--</p>
      </div>
      <template #footer>
        <el-button style="margin-right: 20px;" @click="handleClose">关 闭</el-button>
      </template>
    </el-dialog>
    <!--  预览 dialog  -->
    <preview-bill-pdf-dialog :visible.sync="visiblePreviewDialog" v-if="visiblePreviewDialog" :url.sync="previewUrl" />
  </div>
</template>

<script>
import { getPriceByMajorId as getPriceByMajorIdApi } from '@/api/price'
import {
  getStuRoomPrices,
  undoStudentOrderApi,
  saveStudentOrderRemarksApi
} from '@/api/finance-manage/studentOrder'
import dialog from '@/vue/mixins/dialog'
import { generateOtherBillPdfApi } from '@/api/finance-manage/pay-type-api'
import { Decimal } from 'decimal.js'
import PreviewBillPdfDialog from '@/views/pages/financeManage/searchOrder/component/previewBillPdfDialog.vue'

export default {
  components: { PreviewBillPdfDialog },
  mixins: [dialog],
  data() {
    return {
      updateFlag: false, // true时 关闭弹窗后刷新列表
      previewUrl: '', // pdf链接
      visiblePreviewDialog: false, // 预览弹窗
      isEditing: false,
      formData: {
        id: '',
        remarks: ''
      },
      // 学生基本信息
      userInfo: {
        examineNo: '',
        stuName: '',
        sexType: '',
        collegeName: '',
        majorName: '',
        className: '',
        dormitoryName: '',
        floorNum: '',
        roomName: '',
        grade: '',
        majorId: '',
        roomTypeId: '',
        userId: ''
      },
      orderList: [],
      allPaidAmount: [],
      allDeductAmount: [],
      allRefundAmount: [],
      majorPriceList: [],
      roomPrices: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getStudentOrderList()
      /* 1.新生必须绑定宿舍之后才能查看缴费详情
      * */
      if (this.userInfo.roomTypeId || (this.userInfo.grade < new Date().getFullYear())) {
        this.getPriceByMajorId({
          majorId: this.userInfo.majorId,
          grade: this.userInfo.grade
        })
        this.getStuRoomPrices(this.userInfo.userId)
      }
    })
  },
  methods: {
    Decimal,
    // 修改备注
    saveRemarks(row) {
      this.$nextTick(() => {
        this.$refs.form[0].validate(valid => {
          if (valid) {
            this.$confirm(`是否确定修改${row.payDate}的备注?`, '修改确认').then(async () => {
              try {
                await saveStudentOrderRemarksApi(this.formData)
                this.updateFlag = true
                this.$message.success('修改成功!')
              } catch (e) {
              } finally {
                this.cancel()
              }
            }).catch(() => {
              this.cancel()
              this.$message.info('取消修改')
            })
          } else {
            return false
          }
        })
      })
    },
    // 取消修改
    cancel() {
      this.isEditing = false
      this.getStudentOrderList()
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = ''
      })
    },
    handleClose() {
      this.updateFlag ? this.closed() : this.close()
    },
    showEditRemarks(row) {
      row.isEditing = true
      this.isEditing = true
      this.$nextTick(() => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = row[key]
        })
      })
    },
    // 撤销(删除)订单数据
    undo(row) {
      this.$confirm(`撤销${row.payDate}(缴费方式:<b>${row.payType || '暂缺'}</b>,金额:<b style="color: var(--color-primary);">${row.payAmount}</b>)的学费订单, 是否继续?`, `撤销确认${this.title}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'danger',
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          try {
            await undoStudentOrderApi(row.id)
            this.$message.success('撤销成功!')
            await this.getStudentOrderList()
            this.updateFlag = true
          } catch (e) {
          }
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消'
          })
        })
    },
    // 根据年份查询学生缴费登记
    getStudentOrderList() {
      if (!this.userInfo.userId) {
        this.$message.error('缴费登记获取失败，请重试。')
        this.closed()
        return
      }
      this.$http
        .getStudentOrderListQueryApi({
          userId: this.userInfo.userId
        })
        .then((res) => {
          const _resData = res.data.list || []
          if (_resData.length) {
            _resData.map(item => {
              Object.assign(item, { isEditing: false })
            })
            const firstYearOrder = _resData.filter(
              ({ year }) => Number(year) === 1
            )
            const secondYearOrder = _resData.filter(
              ({ year }) => Number(year) === 2
            )
            const thirdYearOrder = _resData.filter(
              ({ year }) => Number(year) === 3
            )
            const onePaidAmount = firstYearOrder.reduce((total, { payAmount }) => {
              return this.handlePlus(total, payAmount)
            }, 0)
            const twoPaidAmount = secondYearOrder.reduce((total, { payAmount }) => {
              return this.handlePlus(total, payAmount)
            }, 0)
            const oneDeductAmount = firstYearOrder.reduce((total, { deductAmount }) => {
              return this.handlePlus(total, deductAmount)
            }, 0)
            const twoDeductAmount = secondYearOrder.reduce((total, { deductAmount }) => {
              return this.handlePlus(total, deductAmount)
            }, 0)
            const oneRefundAmount = firstYearOrder.reduce((total, { refundAmount }) => {
              return this.handlePlus(total, refundAmount) || 0
            }, 0)
            const twoRefundAmount = secondYearOrder.reduce((total, { refundAmount }) => {
              return this.handlePlus(total, refundAmount) || 0
            }, 0)
            this.allPaidAmount = [onePaidAmount, twoPaidAmount]
            this.allDeductAmount = [oneDeductAmount, twoDeductAmount]
            this.allRefundAmount = [oneRefundAmount, twoRefundAmount]
            this.orderList = [firstYearOrder, secondYearOrder]

            let threePaidAmount = []
            let threeRefundAmount = []
            let threeDeductAmount = []
            if (this.majorPriceList.length === 3) {
              threePaidAmount = thirdYearOrder.reduce((total, { payAmount }) => {
                return this.handlePlus(total, payAmount)
              }, 0)
              threeRefundAmount = thirdYearOrder.reduce((total, { refundAmount }) => {
                return this.handlePlus(total, refundAmount) || 0
              }, 0)
              threeDeductAmount = thirdYearOrder.reduce((total, { deductAmount }) => {
                return this.handlePlus(total, deductAmount)
              }, 0)
              this.allPaidAmount.push(threePaidAmount)
              this.allDeductAmount.push(threeDeductAmount)
              this.allRefundAmount.push(threeRefundAmount)
              this.orderList.push(thirdYearOrder)
            }
          } else {
            this.orderList = [[], [], []]
          }
        }).catch(e => {
        console.error(e)
      })
    },
    // 获取欠费
    getOwnAmount(index) {
      return new Decimal(Number(this.majorPriceList[index].allFee)).add(new Decimal(Number(this.roomPrices[index]?.roomPrice || 0))).sub(new Decimal(Number(this.allPaidAmount[index]))).sub(Number(this.allDeductAmount[index])).toNumber()
    },
    // 加法运算
    handlePlus(num1, num2) {
      return new Decimal(Number(num1)).add(new Decimal(Number(num2))).toNumber()
    },
    // 根据id获取年度费用信息
    getPriceByMajorId(params) {
      getPriceByMajorIdApi(params).then((res) => {
        this.majorPriceList = res.data.feeList.sort(
          (a, b) => a.academicYear - b.academicYear
        )
      }).catch(e => {
        console.log('getPriceByMajorId' + e)
      })
    },
    // 根据id获取宿舍费用
    getStuRoomPrices(userId) {
      getStuRoomPrices(userId).then((res) => {
        this.roomPrices = res.data
      })
    },
    /* 打印预览 电子票据 */
    async previewBill(row) {
      this.loadData.loading = true
      try {
        const res = await generateOtherBillPdfApi(row.id)
        this.previewUrl = `${this.$baseUrl}/stu/stuTuitionOrder/downloadPayBillFile/${res.data}`
        this.visiblePreviewDialog = true
        this.loadData.loading = false
      } catch (e) {
      }
    }
  }
}
</script>
<style lang="scss">
.stu-order-detail-dialog {
  > .el-dialog {
    margin-top: 5vh !important;

    .order-table {
      margin-top: 40px;

      .order-info {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .el-table {
      .el-table__cell.remarks {
        padding-bottom: 0;
      }
    }
  }
}
</style>
