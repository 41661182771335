<!--缴费登记-->
<template>
  <table-view
    :total="total"
    v-loading="loading"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        :queryInfo.sync="queryInfo"
        :tool-list="['grade',  'college', 'major', 'stuStatus','keyword']"
        :insert-select-all="[ 'college', 'major']"
        keyword-placeholder="录取编号、姓名、学号"
        @on-search="renderTable(1)"
      />
      <div class="header-button">
        <upload-excel :permit-key="['stu:order:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importStudentOrder"
                      @on-download="$http.exportExcelHttp.exportStudentOrderTemplate()"
                      @on-success="renderTable(1)" />
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe v-loading="loading">
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="examineNo" label="录取编号" width="120" />
      <el-table-column prop="stuNo" label="学号" width="120" align="center">
        <template v-slot="{row}">
          <span v-if="row.stuStatus">{{ row.stuNo }}</span>
          <span v-else class="font-grey">--</span>
        </template>
      </el-table-column>
      <el-table-column prop="stuName" label="学生姓名" width="140" />
      <el-table-column prop="sexType" label="性别" width="50">
        <template v-slot="{row}">
          <span v-if="Number(row.sexType) === 1">男</span>
          <span v-else>女</span>
        </template>
      </el-table-column>
      <el-table-column label="学籍状态" width="120" align="center">
        <template v-slot="{row}">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="majorName" label="专业" width="200" />
      <el-table-column prop="className" label="班级" width="200">
        <template v-slot="{row}">
          {{ row.className || '未绑定' }}
        </template>
      </el-table-column>
      <el-table-column label="宿舍" width="150">
        <template v-slot="{row}">
            <span v-if="row.dormitoryName && row.floorNum"
            >{{ row.dormitoryName }}{{ row.roomName }}</span
            >
          <span v-else>未分配</span>
        </template>
      </el-table-column>
      <el-table-column prop="yearOnePayStatus" label="第一学年" width="100" />
      <el-table-column prop="yearTwoPayStatus" label="第二学年" width="100" />
      <el-table-column prop="yearThreePayStatus" label="第三学年" width="100" />
      <el-table-column label="" width="280" fixed="right">
        <template #header>
          <tool-tips-group
            tips-content="1. 新生：<span class='font-danger'>绑定了宿舍</span>后，才能填写<b>学生信息登记</b>，之后才能进行<b>缴费登记</b>；<br/> 2. 老生不需要绑定宿舍，也能进行缴费登记。">
            操作
          </tool-tips-group>
        </template>
        <template v-slot="{row}">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="showStuOrderDialog(row)"
          >缴费详情
          </el-button>
          <!-- 1. 新生：row.roomTypeId=存在宿舍，才能进行缴费 -->
          <!-- 2. 老生 年级小于当前年 不需要存在宿舍-->
          <template v-if="row.roomTypeId||(new Date().getFullYear()>+row.grade)">
            <!--fillBachelorIntention==true(填写专升本意向后)，可进行缴费登记-->
            <template v-if="row.fillBachelorIntention">
              <el-button
                type="primary"
                size="mini"
                @click="showDialog(row)"
              >缴费登记
              </el-button>
              <el-button
                type="primary"
                plain
                size="mini"
                @click="showRoomFeeDialog(row)"
              >宿舍费设置
              </el-button>
            </template>
            <!--  未填写专业意向，先进行学生信息登记    -->
            <el-button
              v-if="!row.fillBachelorIntention"
              type="primary"
              size="mini"
              @click="showDialog(row)"
            >学生信息登记
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新建/编辑学生学费登记 fillBachelorIntention===true(填写专业意向后) -->
    <addStudentOrder ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
    <!--  学生信息登记 fillBachelorIntention===false -->
    <addStudentInfo ref="stuInfoDialogRef" :visible.sync="visibleStuInfoDialog" v-if="visibleStuInfoDialog"
                    @on-close="renderTable" />
    <!--  查看订单详情  -->
    <studentOrderDetail ref="stuOrderDialogRef" :visible.sync="visibleStuOrderDialog" v-if="visibleStuOrderDialog"
                        @on-close="renderTable" />
    <!--  宿舍费用设置  -->
    <dialog-set-room-fee ref="dialogSetRoomFeeRef" :visible.sync="visibleRoomFeeDialog" v-if="visibleRoomFeeDialog" />
  </table-view>
</template>

<script>
import { deleteStudentOrderApi } from '@/api/finance-manage/studentOrder'
import { getStudentOrderList } from '@/api/student'
import addStudentOrder from '../dialog/addStudentOrder.vue'
import addStudentInfo from '../dialog/addStudentInfo.vue'
import studentOrderDetail from '../dialog/studentOrderDetail.vue'
import tableView from '@/vue/mixins/table-view'
import { copyData } from '@/utils/common-js'
import DialogSetRoomFee from '@/views/pages/financeManage/student-order/component/DialogSetRoomFee.vue'

export default {
  components: {
    DialogSetRoomFee,
    addStudentOrder,
    studentOrderDetail,
    addStudentInfo
  },
  mixins: [tableView],
  data() {
    return {
      visibleRoomFeeDialog: false, // 宿舍费设置
      visibleStuInfoDialog: false, // 学生信息登记
      visibleStuOrderDialog: false, // 学生订单详情
      queryInfo: {
        stuStatus: null,
        collegeId: null,
        majorId: null,
        grade: new Date().getFullYear()
      },
      tableLoading: false
    }
  },
  methods: {
    deleteStudentOrderApi, // 删除api
    // 获取学生列表
    renderTable(pageNum) {
      this.getTableData(getStudentOrderList, pageNum)
    },
    // 学费订单登记
    async showDialog(row) {
      try {
        // fillBachelorIntention:true-是否已经确认并填写好专升本意向
        if (row.fillBachelorIntention) {
          // 打开 学费订单信息 dialog
          this.visibleDialog = true
          this.$nextTick(() => {
            copyData(row, this.$refs.dialogRef.userInfo)
            this.$refs.dialogRef.formData.userId = row.userId
          })
        } else { // 打开 学生信息登记 dialog
          this.visibleStuInfoDialog = true
          this.$nextTick(() => {
            this.$refs.stuInfoDialogRef.id = row.id
            this.$refs.stuInfoDialogRef.title = row
          })
        }
      } catch (e) {
      }
    },
    // 打开订单详情
    showStuOrderDialog(row) {
      this.visibleStuOrderDialog = true
      this.$nextTick(() => {
        this.$refs.stuOrderDialogRef.id = row.id
        const _stuInfo = row.stuNo ? `学号:${row.stuNo}` : `录取编号:${row.examineNo}`
        this.$refs.stuOrderDialogRef.title = `-${row.stuName}(${_stuInfo})`
        Object.keys(this.$refs.stuOrderDialogRef.userInfo).forEach(key => {
          this.$refs.stuOrderDialogRef.userInfo[key] = row[key]
        })
      })
    },
    showRoomFeeDialog(row) {
      this.visibleRoomFeeDialog = true
      this.$nextTick(() => {
        this.$refs.dialogSetRoomFeeRef.stuData = row
        this.$refs.dialogSetRoomFeeRef.preTitle = `${row.stuName}(${row.stuNo ? '学号:' + row.stuNo : '录取编号:' + row.examineNo})-`
      })
    }
  }
}
</script>
