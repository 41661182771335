<!--按学年设置宿舍费-->
<template>
  <el-dialog
    class="dialog-set-room-fee"
    :title="`${preTitle}宿舍费用设置`"
    :visible.sync="show"
    :before-close="close"
    width="520px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="0">
      <el-table :data="formData.tableData" border stripe>
        <el-table-column label="学年" width="100" align="center">
          <template v-slot="{row}">
            第{{ row.year }}学年
          </template>
        </el-table-column>
        <el-table-column label="宿舍费用">
          <template v-slot="{row,$index}">
            <el-form-item v-if="row.isEditing" :prop="`tableData.${$index}.roomPrice`"
                          :rules="rules.roomPrice">
              <el-input v-model="row.roomPrice" placeholder="请输入宿舍费用" />
            </el-form-item>
            <div v-else :class="[{'font-grey':row.disabled}]">{{ row.roomPrice }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160px">
          <template v-slot="{row}">
            <template v-if="row.isEditing">
              <el-form-item>
                <el-button size="small" @click="cancel(row)">取消</el-button>
                <el-button type="primary" size="small" v-if="row.isEditing" @click="handleSave(row)">保存
                </el-button>
              </el-form-item>
            </template>
            <template v-else>
              <template v-if="!isEditing&&!row.disabled">
                <el-button v-permission="['searchOrder:payType:edit']" size="small" type="primary" plain
                           @click="editRow(row)">编辑
                </el-button>
              </template>
              <!-- <span v-if="scope.row.disabled" class="font-grey"></span>-->
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <template #footer>
      <el-button @click="close">关 闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getStudentOrderListQueryApi, getStuRoomPrices } from '@/api/finance-manage/studentOrder'
import { Decimal } from 'decimal.js'
import { updateRoomPriceByUserIdApi } from '@/api/student'

export default {
  mixins: [dialog],
  props: {
    addData: {
      type: Object
    },
    departmentList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      stuData: undefined,
      isEditing: false,
      formData: {
        tableData: []
      },
      rules: {
        roomPrice: [
          {
            required: true,
            message: '请填写费用',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorTwoDecimalPlacesObj
        ]
      }
    }
  },
  async mounted() {
    await this.$nextTick()
    if (this.stuData?.userId) {
      await this.getStuRoomPrices(this.stuData)
    } else {
      this.$message.error('暂无宿舍信息！')
      this.close()
    }
  },
  methods: {
    // 根据id获取宿舍费用
    async getStuRoomPrices(stuInfo) {
      try {
        this.loadData.loading = true
        const orderRes = await getStudentOrderListQueryApi({ userId: this.stuData.userId })
        const res = await getStuRoomPrices(stuInfo.userId)
        const _array = []
        res.data.forEach(({
                            year,
                            roomPrice
                          }) => {
          /* 可编辑的两个并存条件：
          *  1. 学年小于于当前年份
          *  2. 已缴费（交了一元也可以调整宿舍费用）
          * */
          // 是否早于或等于当前年份
          let isDisabled = new Decimal(+stuInfo.grade).add(year).sub(1).toNumber() > new Date().getFullYear()
          if (!isDisabled) {
            if (orderRes.data.list.length) {
              // 判断是否有付款
              isDisabled = !(orderRes.data.list.some(item => item.year === year))
            } else {
              isDisabled = true
            }
          }
          _array.push({
            year: year,
            roomPrice: roomPrice,
            disabled: isDisabled, // 不能修改未来的宿舍费（如今年2024年，不能修改2025的）
            isEditing: false
          })
        })
        this.formData.tableData = _array
      } catch (e) {

      } finally {
        this.loadData.loading = false
      }
    },
    editRow(row) {
      row.isEditing = true
      this.isEditing = true
    },
    async cancel(row) {
      row.isEditing = false
      this.isEditing = false
      await this.getStuRoomPrices(this.stuData)
    },
    async handleSave(row) {
      await this.$refs.form.validate(async (valid) => {
        if (!valid) {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
        this.$confirm(`是否确认更新<b>${this.preTitle}第${row.year}学年</b>的宿舍费?`, '保存提示', {
          dangerouslyUseHTMLString: true
        }).then(async () => {
          try {
            await updateRoomPriceByUserIdApi({
              roomPrice: row.roomPrice,
              userId: this.stuData.userId,
              year: row.year
            })
            this.$message.success('更新成功')
            await this.getStuRoomPrices(this.stuData)
            this.isEditing = false
          } catch (e) {
            console.error(e)
          } finally {
          }
        })
      })
    }
  }
}
</script>
