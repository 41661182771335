var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"queryInfo":_vm.queryInfo,"tool-list":['grade',  'college', 'major', 'stuStatus','keyword'],"insert-select-all":[ 'college', 'major'],"keyword-placeholder":"录取编号、姓名、学号"},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}}),_c('div',{staticClass:"header-button"},[_c('upload-excel',{attrs:{"permit-key":['stu:order:edit'],"loading":_vm.loading,"api":_vm.$http.importExcelHttp.importStudentOrder},on:{"update:loading":function($event){_vm.loading=$event},"on-download":function($event){return _vm.$http.exportExcelHttp.exportStudentOrderTemplate()},"on-success":function($event){return _vm.renderTable(1)}}})],1)]},proxy:true}])},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"120"}}),_c('el-table-column',{attrs:{"prop":"stuNo","label":"学号","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.stuStatus)?_c('span',[_vm._v(_vm._s(row.stuNo))]):_c('span',{staticClass:"font-grey"},[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"prop":"stuName","label":"学生姓名","width":"140"}}),_c('el-table-column',{attrs:{"prop":"sexType","label":"性别","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(Number(row.sexType) === 1)?_c('span',[_vm._v("男")]):_c('span',[_vm._v("女")])]}}])}),_c('el-table-column',{attrs:{"label":"学籍状态","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.stuStatus, _vm.$store.state.systemParam.stuStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","width":"200"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className || '未绑定')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"宿舍","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName && row.floorNum)?_c('span',[_vm._v(_vm._s(row.dormitoryName)+_vm._s(row.roomName))]):_c('span',[_vm._v("未分配")])]}}])}),_c('el-table-column',{attrs:{"prop":"yearOnePayStatus","label":"第一学年","width":"100"}}),_c('el-table-column',{attrs:{"prop":"yearTwoPayStatus","label":"第二学年","width":"100"}}),_c('el-table-column',{attrs:{"prop":"yearThreePayStatus","label":"第三学年","width":"100"}}),_c('el-table-column',{attrs:{"label":"","width":"280","fixed":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tool-tips-group',{attrs:{"tips-content":"1. 新生：<span class='font-danger'>绑定了宿舍</span>后，才能填写<b>学生信息登记</b>，之后才能进行<b>缴费登记</b>；<br/> 2. 老生不需要绑定宿舍，也能进行缴费登记。"}},[_vm._v(" 操作 ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){return _vm.showStuOrderDialog(row)}}},[_vm._v("缴费详情 ")]),(row.roomTypeId||(new Date().getFullYear()>+row.grade))?[(row.fillBachelorIntention)?[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row)}}},[_vm._v("缴费登记 ")]),_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){return _vm.showRoomFeeDialog(row)}}},[_vm._v("宿舍费设置 ")])]:_vm._e(),(!row.fillBachelorIntention)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row)}}},[_vm._v("学生信息登记 ")]):_vm._e()]:_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('addStudentOrder',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleStuInfoDialog)?_c('addStudentInfo',{ref:"stuInfoDialogRef",attrs:{"visible":_vm.visibleStuInfoDialog},on:{"update:visible":function($event){_vm.visibleStuInfoDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleStuOrderDialog)?_c('studentOrderDetail',{ref:"stuOrderDialogRef",attrs:{"visible":_vm.visibleStuOrderDialog},on:{"update:visible":function($event){_vm.visibleStuOrderDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleRoomFeeDialog)?_c('dialog-set-room-fee',{ref:"dialogSetRoomFeeRef",attrs:{"visible":_vm.visibleRoomFeeDialog},on:{"update:visible":function($event){_vm.visibleRoomFeeDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }